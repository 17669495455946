import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUsers } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import EditUser from "../components/EditUser";
import Layout from "../components/Layout";

const EditUserPage = () => {
	const { token } = useContext(StoreProvider);
	const [user, setUser] = useState({});
	const { user_id } = useParams();
	const loadUser = async (id) => {
		const res = await getUsers(id, token);
		if (!res?.isError) {
			delete res?.user?.my_products
			setUser(res?.user);
		}
	};
	useEffect(() => {
		loadUser(user_id);
	}, [user_id]);
	return (
		<Layout>
			<EditUser user={user} setUser={setUser} />
		</Layout>
	);
};

export default EditUserPage;
