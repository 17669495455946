import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generateCookie, login } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import styles from "../styles/login.module.scss";

const Login = () => {
  const { setAdmin, setToken } = useContext(StoreProvider);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const handleLogin = async (data) => {
    setIsLoading(true);
    const res = await login(data);
    setResponse(res);
    setIsLoading(false);
    if (!res?.isError && res?.encrypted_token) {
      setToken(res?.encrypted_token);
      document.cookie = generateCookie(res?.encrypted_token, 30);
      setAdmin({
        isError: false,
        isVerified: true,
        message: "Admin is authenticated",
      });
      navigate("/", { replace: true });
    } else {
      document.cookie = generateCookie("", 0);
    }
  };
  return (
    <Layout>
      <div
        style={{ height: "80vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className={styles.login}>
          <p className="text-danger">{response?.message}</p>
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                {...register("email", { required: true })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                minLength={6}
                {...register("password", { required: true })}
              />
            </div>
            <button
              type="submit"
              disabled={isLoading ? true : false}
              className="btn btn-primary"
            >
              Login {isLoading && <Spinner />}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
