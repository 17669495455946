import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import { getAllFile } from "../api";
// import { StoreProvider } from "../components/ContextProvider";
import FileManagerModal from "../components/FileManager/FileManagerModal";
import Layout from "../components/Layout";

const FilePage = () => {
  // const { token } = useContext(StoreProvider);
  // const [files, setFiles] = useState([]);
  // const { category } = useParams();

  // const loadFiles = async (category) => {
  //   const res = await getAllFile(category, token);
  //   if (!res?.isError) {
  //     setFiles(res?.files);
  //   }
  // };

  // useEffect(() => {
  //   loadFiles(category);
  // }, [category]);

  const [openModal, setOpenModal] = useState(true);

  return (
    <Layout>
      <FileManagerModal openModal={openModal} setOpenModal={setOpenModal} />
      {/* <File
        files={files}
        token={token}
        setFiles={setFiles}
        category={category}
      /> */}
    </Layout>
  );
};

export default FilePage;
