import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUsers } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";
import UserDetail from "../components/UserDetail";

const UserDetailPage = () => {
	const { token } = useContext(StoreProvider);
	const [user, setUser] = useState({});
	const [allAccess, setAllAccess] = useState({});
	const { user_id } = useParams();
	const loadUser = async (id) => {
		const res = await getUsers(id, token);
		if (!res?.isError) {
			const all = await res?.user?.my_products.filter(
				(item) => item?.product_id === "All-Access"
			);
			if (all?.length > 0) {
				setAllAccess(all[0]);
			}
			setUser(res?.user);
		}
	};
	useEffect(() => {
		loadUser(user_id);
	}, [user_id]);
	return (
		<Layout>
			<UserDetail user={user} allAccess={allAccess} />
		</Layout>
	);
};

export default UserDetailPage;
