import React, { useContext, useEffect, useState } from "react";
import { addDiscount, deleteDiscount, getAllDiscount } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";

const Discount = () => {
	const { token } = useContext(StoreProvider);
	const [allDiscount, setAllDiscount] = useState([]);
	const [discount, setDiscount] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [error, setError] = useState({});

	const loadAllDiscount = async (token) => {
		const response = await getAllDiscount(token);
		if (!response?.isError) {
			setAllDiscount(response?.discounts);
		}
	};

	const handleAddDiscount = async (e) => {
		e.preventDefault();
		const { name, discount_code, discount_value } = discount;
		if (name && discount_code && discount_value) {
			setIsLoading(true);
			setError({});
			const formData = new FormData();
			formData.append("name", name);
			formData.append("discount_code", discount_code);
			formData.append("discount_value", discount_value);
			const res = await addDiscount(formData, token);
			setError(res);
			setIsLoading(false);
			if (!res?.isError) {
				loadAllDiscount(token);
				// window.location.reload();
			}
		}
	};

	const handleDelete = async (discount_code) => {
		const confirm = window.confirm("Are sure to remove this coupon?");
		if (confirm) {
			setDeleteId(discount_code);
			const response = await deleteDiscount(discount_code, token);
			setError(response);
			setDeleteId("");
			if (!response?.isError) {
				const newItem = allDiscount.filter(
					(item) => item?.discount_code !== discount_code
				);
				setAllDiscount(newItem);
			}
		}
	};

	useEffect(() => {
		loadAllDiscount(token);
	}, [token]);

	return (
		<Layout>
			<h2 className="card-title mt-3 mb-3">Discount Price</h2>
			<p className={error?.isError ? "text-danger" : "text-success"}>
				{error?.message}
			</p>
			<form onSubmit={handleAddDiscount}>
				<div className="d-flex justify-content-between align-items-center mb-4 form-group">
					<div className="">
						<label htmlFor="name">
							Name: <span className="text-danger">*</span>
						</label>
						<input
							onChange={(e) =>
								setDiscount({ ...discount, name: e.target.value })
							}
							type="text"
							className="form-control"
							id="name"
							placeholder="Name"
							required
						/>
					</div>
					<div className="">
						<label htmlFor="code">
							Discount Code: <span className="text-danger">*</span>
						</label>
						<input
							onChange={(e) =>
								setDiscount({ ...discount, discount_code: e.target.value })
							}
							type="text"
							className="form-control"
							id="code"
							placeholder="New Price"
							required
						/>
					</div>
					<div className="">
						<label htmlFor="price">
							Discount Amount: <span className="text-danger">*</span>
						</label>
						<input
							onChange={(e) =>
								setDiscount({ ...discount, discount_value: e.target.value })
							}
							type="text"
							className="form-control"
							id="price"
							min={1}
							max={100}
							maxLength={3}
							placeholder="Discount Amount"
							required
						/>
					</div>
				</div>
				<button
					disabled={isLoading ? true : false}
					type="submit"
					className="btn btn-primary"
				>
					Add Discount {isLoading && <Spinner />}
				</button>
			</form>
			<div className="mt-4">
				<h4 className="card-title mt-3 mb-3">All Discount Price</h4>
				<div style={{ flexWrap: "wrap" }} className="d-flex align-items-center">
					{allDiscount.map((item) => (
						<div
							key={item?.discount_code}
							className="card me-5 mb-4"
							style={{ width: "18rem" }}
						>
							<h6 className="card-header">{item?.name}</h6>
							<div className="card-body p-4">
								<h6 className="card-subtitle mb-3">
									Discount Code: {item?.discount_code}
								</h6>
								<h6 className="card-subtitle mb-2">
									Discount Amount: {item?.discount_value}%
								</h6>
								<div className="mt-4 text-end">
									<button
										disabled={deleteId === item?.discount_code ? true : false}
										onClick={() => handleDelete(item?.discount_code)}
										className="btn btn-danger"
									>
										Delete {deleteId === item?.discount_code && <Spinner />}
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
};

export default Discount;
