import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";

const Home = () => {
  const { allProducts } = useContext(StoreProvider);

  return (
    <Layout>
      <div
        className="d-flex justify-content-around"
        style={{ flexWrap: "wrap" }}
      >
        {allProducts.map((product) => (
          <div
            key={product?.product_id}
            className="card ms-1 me-1 mt-5 mb-3"
            style={{ width: "19rem" }}
          >
            <Link to={`/detail/${product?.product_id}`}>
              <img
                className="card-img-top"
                src={`${process.env.REACT_APP_SERVER_CONNECT}/public/file/${product?.image}`}
                alt={product?.name?.slice(0, 15)}
              />
            </Link>
            <div className="card-body">
              <Link
                style={{ textDecoration: "none" }}
                title="detail"
                to={`/detail/${product?.product_id}`}
              >
                <h5 title={product?.name} className="card-title">
                  {product?.name?.slice(0, 40)}...
                </h5>
              </Link>
              <p className="card-text">
                {product?.short_detail?.slice(0, 120)}...
              </p>
              <p className="m-0">
                <span className="fw-bold">Total sales: </span>
                {product?.sales}
              </p>
              <p className="mt-1">
                <span className="fw-bold">Free download: </span>
                {product?.free_download}
              </p>
              <div className="d-flex justify-content-end">
                <Link title="detail" to={`/detail/${product?.product_id}`}>
                  <button className="btn btn-info me-5 ps-3 pe-3">
                    Detail
                  </button>
                </Link>
                <Link title="detail" to={`/edit/${product?.product_id}`}>
                  <button className="btn btn-warning ps-4 pe-4">Edit</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Home;
