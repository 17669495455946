import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteProduct, getAllProducts } from "../api";
import "../styles/detailpage.css";
import styles from "../styles/productDetail.module.scss";
import { StoreProvider } from "./ContextProvider";

const ProductDetail = ({ product }) => {
  const { setAllProduct, token } = useContext(StoreProvider);
  const navigate = useNavigate();
  const handleDelete = async (id) => {
    const confirm = window.confirm("Are sure delete this product");
    if (confirm) {
      const deleted = await deleteProduct(id, token);
      if (!deleted?.isError) {
        const newProducts = await getAllProducts(token);
        setAllProduct(newProducts?.products);
        navigate("/");
      }
    }
  };
  return (
    <div className={styles.productDetail}>
      <div className={styles?.wrapper}>
        <div>
          <div className="card mb-3 mt-3">
            <img
              className={styles?.coverImg}
              src={`${process.env.REACT_APP_SERVER_CONNECT}/public/file/${product?.image}`}
              alt=""
              loading="lazy"
            />
            <div className="d-flex justify-content-around p-4">
              <Link
                className="ms-5"
                title="detail"
                to={`/edit/${product?.product_id}`}
              >
                <button className="btn btn-warning ps-4 pe-4">Edit</button>
              </Link>
              <button
                onClick={() => handleDelete(product?.product_id)}
                className="btn btn-danger ms-5"
              >
                Delete
              </button>
            </div>
            <div className="card-body pt-0 p-4">
              <h2 className="mb-3 card-title">{product?.name}</h2>
              <div className=" mb-5">
                <p>Slug: {product?.slug}</p>
                <p>Is Featured: {product?.is_featured ? "Yes" : "No"}</p>
                <p>
                  Tags:{" "}
                  {product?.tags?.map((tag) => (
                    <span
                      className="p-1 me-2 rounded text-light bg-info"
                      key={tag?.id}
                    >
                      {tag?.name}
                    </span>
                  ))}
                </p>
                <p>Built with: {product?.built_with}</p>
                <p>Version: {product?.current_version}</p>

                <p>
                  Created:{" "}
                  {new Date(product?.created_at).toLocaleDateString("en-GB")}
                </p>
                <p>
                  Last Update:{" "}
                  {new Date(product?.updated_at).toLocaleDateString("en-GB")}
                </p>
                <p>
                  Preview Link:{" "}
                  <a
                    href={product?.preview_link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {product?.preview_link}
                  </a>
                </p>
                <p>Total sales: {product?.sales}</p>
                <p>Total earnings: ${product?.earnings}</p>
                <p>Total Free Download: {product?.free_download}</p>
                <p>Default Variation : {product?.default_variation}</p>
                <h5>Price</h5>
                {product?.price?.starter > 0 && (
                  <p>Starter: ${product?.price?.starter}</p>
                )}
                {product?.price?.business > 0 && (
                  <p>Business: ${product?.price?.business}</p>
                )}
                {product?.price?.extend > 0 && (
                  <p>Extend: ${product?.price?.extend}</p>
                )}
                <h5>Download File</h5>
                {product?.download_file?.freeFile.length > 0 && (
                  <p>Free: {product?.download_file?.freeFile}</p>
                )}
                {product?.download_file?.starterFile.length > 0 && (
                  <p>Starter: {product?.download_file?.starterFile}</p>
                )}
                {product?.download_file?.businessFile.length > 0 && (
                  <p>Business: {product?.download_file?.businessFile}</p>
                )}
                {product?.download_file?.extendFile.length > 0 && (
                  <p>Extended: {product?.download_file?.extendFile}</p>
                )}
                <h5>License File</h5>
                {product?.download_file_license?.freeFile.length > 0 && (
                  <p>Free: {product?.download_file_license?.freeFile}</p>
                )}
                {product?.download_file_license?.starterFile.length > 0 && (
                  <p>Starter: {product?.download_file_license?.starterFile}</p>
                )}
                {product?.download_file_license?.businessFile.length > 0 && (
                  <p>
                    Business: {product?.download_file_license?.businessFile}
                  </p>
                )}
                {product?.download_file_license?.extendFile.length > 0 && (
                  <p>Extended: {product?.download_file_license?.extendFile}</p>
                )}
              </div>
              <h4>Product Detail</h4>
              <div
                className="w-100 detail_body"
                dangerouslySetInnerHTML={{ __html: product?.detail }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
