import ImageUploader from "quill-image-uploader";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import {
  addProduct,
  getAllProducts,
  getAllTitleDesc,
  imageHandler,
} from "../api";
import styles from "../styles/AddProduct.module.scss";
import { StoreProvider } from "./ContextProvider";
import FileManagerModal from "./FileManager/FileManagerModal";
import Spinner from "./Spinner";
import { toast } from "react-toastify";

Quill.register("modules/imageUploader", ImageUploader);
const modules = {
  toolbar: [
    [{ font: ["inter"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    ["clean"],
  ],
  imageUploader: {
    upload: imageHandler,
  },
};

const AddProduct = () => {
  const { setAllProduct, token } = useContext(StoreProvider);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [value, setValue] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFeatured, setIsFeatured] = useState(true);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [short_detail, setShortDetail] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileStatus, setFileStatus] = useState({});
  const [zipFiles, setZipFiles] = useState({});
  const [licenseFiles, setLicenseFiles] = useState({});
  const [defaultVariation, setDefaultVariation] = useState("select");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAdd = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append(
      "slug",
      data?.slug?.toString().trim().replace(/\s+/g, "-").toLowerCase()
    );
    formData.append("built_with", data?.built_with);
    formData.append("current_version", data?.version);
    formData.append("preview_link", data?.preview_link);
    formData.append("short_detail", short_detail);
    formData.append("is_featured", isFeatured);
    formData.append(
      "price",
      JSON.stringify({
        free: data?.freeZipFilename?.length > 0 ? 0 : 120,
        starter: data?.starterZipFilename?.length > 0 ? data?.starterPrice : 0,
        business:
          data?.businessZipFilename?.length > 0 ? data?.businessPrice : 0,
        extend: data?.extendZipFilename?.length > 0 ? data?.extendedPrice : 0,
      })
    );
    formData.append("sales", 0);
    formData.append("earnings", 0);
    formData.append("default_variation", defaultVariation);
    formData.append("free_download", 0);
    formData.append("image", featuredImage);
    formData.append(
      "download_file",
      JSON.stringify({
        freeFile: zipFiles?.freeFile || "",
        starterFile: zipFiles?.starterFile || "",
        businessFile: zipFiles?.businessFile || "",
        extendFile: zipFiles?.extendFile || "",
      })
    );
    formData.append(
      "download_file_license",
      JSON.stringify({
        freeFile: licenseFiles?.freeFile || "",
        starterFile: licenseFiles?.starterFile || "",
        businessFile: licenseFiles?.businessFile || "",
        extendFile: licenseFiles?.extendFile || "",
      })
    );
    formData.append("tags", JSON.stringify(selectedTags));
    formData.append("detail", value || " ");
    const response = await addProduct(formData, token);
    setIsLoading(false);
    // setError(response);
    if (!response?.isError) {
      const newProducts = await getAllProducts(token);
      toast.success(response?.message || "Product added successfully")
      setAllProduct(newProducts?.products);
      navigate(`/detail/${response?.product?.product_id}`);
      return;
    }
    toast.error(response?.message || "An error occurred, please try again");
  };

  const handleTags = (tagString) => {
    if (tagString) {
      const tag = JSON.parse(tagString);
      const existingTag = selectedTags.find(
        (item) => String(item?.id) === String(tag?.id)
      );
      if (!existingTag) {
        setSelectedTags([...selectedTags, tag]);
      }
    }
  };

  const removeTag = (tagId) => {
    const existingTag = selectedTags.filter(
      (item) => String(item?.id) !== String(tagId)
    );
    setSelectedTags(existingTag);
  };

  const handleEditor = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setShortDetail(
      editor
        ?.getText()
        ?.slice(0, 140)
        ?.replace(/\s{2,}/g, " ")
        ?.trim()
    );
  };

  const handleSelectPreviewFile = (selectedFile) => {
    if (fileStatus?.type === "template") {
      const newFile = {
        ...zipFiles,
        [fileStatus.status]: selectedFile?.filename,
      };
      setZipFiles(newFile);
    } else if (fileStatus?.type === "license") {
      const newLicenseFile = {
        ...licenseFiles,
        [fileStatus.status]: selectedFile?.filename,
      };
      setLicenseFiles(newLicenseFile);
    } else if (fileStatus?.type === "featuredImage") {
      setFeaturedImage(selectedFile?.filename);
    }
  };

  const handleFileManagerModal = (type, status) => {
    setIsModalOpen(true);
    setFileStatus({ type, status });
  };

  const loadTags = async () => {
    const response = await getAllTitleDesc(token);
    if (!response?.isError && response?.data) {
      setAllTags(response?.data);
    }
  };

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <>
      <div className={styles.AddProduct}>
        <div className={styles.wrapper}>
          <h3>Add Product To Database</h3>
          <div className={styles.formContainer}>
            <form
              onSubmit={handleSubmit(handleAdd)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <div className={`${styles.basic}`}>
                <div className="mb-3 me-5 w-50">
                  <label htmlFor="name" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                </div>
                <div className="mb-3 w-50">
                  <label htmlFor="slug" className="form-label">
                    Slug <span className="text-danger">*</span>{" "}
                    <small>slug must be unique for every item</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    placeholder="slug"
                    {...register("slug", { required: true })}
                  />
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 me-5 w-50">
                  <label htmlFor="built" className="form-label">
                    Built with
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="built"
                    {...register("built_with", { required: true })}
                    placeholder="example: Tailwind"
                  />
                </div>
                <div className="mb-3 w-50">
                  <label htmlFor="version" className="form-label">
                    Version
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="version"
                    placeholder="example: 0.1.1"
                    {...register("version", { required: true })}
                  />
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 me-5 w-50">
                  <label htmlFor="preview" className="form-label">
                    Preview Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="preview"
                    placeholder="example: https://www.exemple-item.preview"
                    {...register("preview_link")}
                  />
                </div>
                <div className="mb-3 w-50 d-flex justify-content-between align-items-center">
                  <p>Featured Image: {featuredImage}</p>
                  <button
                    type="button"
                    onClick={() =>
                      handleFileManagerModal("featuredImage", "featuredImage")
                    }
                    className="btn btn-primary ms-auto"
                  >
                    Select
                  </button>
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 w-50">
                  <label htmlFor="isFeatured" className="form-label">
                    Is Featured
                  </label>
                  <select
                    className="form-control"
                    id="isFeatured"
                    onChange={(e) => setIsFeatured(JSON.parse(e.target.value))}
                  >
                    <option value={true} defaultValue>
                      Yes
                    </option>
                    <option value={false}>No</option>
                  </select>
                </div>
                <div className="mb-3 ms-5 w-50">
                  <img
                    width={500}
                    // src={
                    //   featuredImage?.size && URL.createObjectURL(featuredImage)
                    // }
                    src={
                      featuredImage &&
                      `${process.env.REACT_APP_SERVER_CONNECT}/public/file/${featuredImage}`
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 w-50">
                  <label htmlFor="taf" className="form-label">
                    Tags
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      if (e?.currentTarget?.value) {
                        handleTags(e?.currentTarget?.value);
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {allTags?.map((tag) => (
                      <option key={tag?.id} value={JSON.stringify(tag)}>
                        {tag?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 ms-4 w-50">
                  {selectedTags?.map((tag) => (
                    <span
                      key={tag?.id}
                      className="block rounded bg-primary text-light p-1 pe-0 me-2 mb-2"
                    >
                      {tag?.name}{" "}
                      <span
                        onClick={() => removeTag(tag?.id)}
                        title="delete"
                        style={{ cursor: "pointer" }}
                        className="rounded-circle ms-2 bg-danger p-1"
                      >
                        x
                      </span>
                    </span>
                  ))}
                </div>
              </div>

              <div className="d-flex justify-content-around">
                {/* product zip filename */}
                <div className="w-50 me-5">
                  <h5 className="mb-4 mt-5">Template zip file</h5>
                  <div className="mb-4 d-flex">
                    <p>Free version: {zipFiles?.freeFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "freeFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Starter version: {zipFiles?.starterFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "starterFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Business version: {zipFiles?.businessFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "businessFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Extended version: {zipFiles?.extendFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "extendFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                </div>
                {/* product txt filename */}
                <div className="w-50 ms-5">
                  <h5 className="mb-4 mt-5">Template license file</h5>
                  <div className="mb-4 d-flex">
                    <p>Free version: {licenseFiles?.freeFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "freeFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Starter version: {licenseFiles?.starterFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "starterFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Business version: {licenseFiles?.businessFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "businessFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Extended version: {licenseFiles?.extendFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "extendFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
              {/* Default Variation */}
              <div className="d-flex align-items-center">
                <h5 className="mb-4 mt-4 me-4">Default Variation: </h5>
                <select
                  className="p-2"
                  onChange={(e) => setDefaultVariation(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="free">Free</option>
                  <option value="starter">Starter</option>
                  <option value="business">Business</option>
                  <option value="extended">Extended</option>
                </select>
              </div>

              {/* price */}
              <h5 className="mb-4 mt-4">Price</h5>
              <div className={`${styles.basic}`}>
                <div className="mb-3 w-25">
                  <label htmlFor="price2" className="form-label">
                    Starter version
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price2"
                    placeholder="example: 50"
                    {...register("starterPrice")}
                  />
                </div>
                <div className="mb-3 me-5 w-25">
                  <label htmlFor="price3" className="form-label">
                    Business version
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price3"
                    placeholder="example: 100"
                    {...register("businessPrice")}
                  />
                </div>
                <div className="mb-3 w-25">
                  <label htmlFor="price4" className="form-label">
                    Extended version
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price4"
                    placeholder="example: 400"
                    {...register("extendedPrice")}
                  />
                </div>
              </div>

              {/* editor */}
              <div className="mt-5 mb-5 text-center">
                <h1>
                  {"<"} Here is Editor {"/>"}
                </h1>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-4 w-75">
                  <label
                    htmlFor="shortDetail"
                    className="fw-bold fs-5 form-label"
                  >
                    Short Detail{" "}
                    <span>
                      {short_detail?.length > 0 ? short_detail?.length : "0"}{" "}
                      /140
                    </span>
                  </label>
                  <textarea
                    style={{ resize: "none" }}
                    type="text"
                    className="form-control"
                    id="shortDetail"
                    placeholder="Short Detail"
                    rows="2"
                    maxLength={140}
                    value={short_detail}
                  // {...register("short_detail", { required: true })}
                  />
                </div>
              </div>
              <h4>Full Detail</h4>
              <ReactQuill
                modules={modules}
                theme="snow"
                value={value}
                onChange={handleEditor}
              />
              <div className="text-center mt-5 mb-5">
                <p
                  className={`mb-4 ${error?.isError ? "text-danger" : "text-success"
                    }`}
                >
                  {error?.error?.sqlMessage || error?.message}
                </p>
                <p className="mb-4 text-danger">
                  Please check again all information provided are correct
                </p>
                <button
                  className="btn btn-primary"
                  disabled={isLoading ? true : false}
                  type="submit"
                >
                  Add Template {isLoading && <Spinner />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FileManagerModal
        isSelect={true}
        openModal={isModalOpen}
        handleSelectPreviewFile={handleSelectPreviewFile}
        setOpenModal={setIsModalOpen}
      />
    </>
  );
};

export default AddProduct;
