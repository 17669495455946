import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../api";
import { StoreProvider } from "./ContextProvider";

const PrivateRoute = ({ children }) => {
  const { admin } = useContext(StoreProvider);
  let location = useLocation();

  const authenticate = getCookie() || (!admin?.isError && admin?.isVerified);
  if (authenticate) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default PrivateRoute;
