import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUsers } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";

const AllUsers = () => {
	const { token } = useContext(StoreProvider);
	const [users, setUsers] = useState([]);
	const [filterUser, setFilterUser] = useState([]);
	const loadUser = async () => {
		const res = await getUsers("", token);
		if (!res?.isError) {
			setUsers(res?.users);
			setFilterUser(res?.users);
		}
	};
	const handleSearch = (e) => {
		const matchedUser = users.filter((user) =>
			user?.email.toLowerCase().includes(e.target.value.trim().toLowerCase())
		);
		setFilterUser(matchedUser);
	};
	useEffect(() => {
		loadUser();
	}, []);

	return (
		<Layout>
			<div className="mt-3 mb-3 d-flex justify-content-between">
				<h2 className="card-title">All Users</h2>
				<div>
					<input
						onKeyDown={handleSearch}
						type="search"
						className="form-control"
						placeholder="Search user by email"
					/>
				</div>
			</div>
			<div className="d-flex" style={{ flexWrap: "wrap" }}>
				{filterUser.map((user) => (
					<div
						key={user?.id}
						className="card ms-4 me-4 mt-5 mb-3"
						style={{ width: "20rem" }}
					>
						<div className="card-body">
							<h5 title={user?.email} className="card-title">
								{user?.email?.length > 20
									? `${user?.email?.slice(0, 20)}...`
									: user?.email}
							</h5>
							<h6 title={user?.name} className="card-title">
								Name:{" "}
								<b>
									{user?.name?.length > 24
										? `${user?.name?.slice(0, 24)}...`
										: user?.name}
								</b>
							</h6>
							<h6 title={user?.first_name} className="card-title">
								First Name:{" "}
								<b>
									{user?.first_name?.length > 15
										? `${user?.first_name?.slice(0, 15)}...`
										: user?.first_name}
								</b>
							</h6>
							<h6 title={user?.last_name} className="card-title">
								Last Name:{" "}
								<b>
									{user?.last_name?.length > 15
										? `${user?.last_name?.slice(0, 15)}...`
										: user?.last_name}
								</b>
							</h6>
							<h6 className="card-title">
								Total Products: <b>{user?.my_products?.length}</b>
							</h6>
							<div className="d-flex justify-content-between mt-4">
								<Link title="Transaction" to={`/user/transaction/${user?.id}`}>
									<button className="btn btn-success ps-2 pe-2">
										Transaction
									</button>
								</Link>
								<Link title="detail" to={`/user/detail/${user?.id}`}>
									<button className="btn btn-info ps-3 pe-3">
										Detail
									</button>
								</Link>
								<Link title="detail" to={`/user/edit/${user?.id}`}>
									<button className="btn btn-warning ps-4 pe-4">Edit</button>
								</Link>
							</div>
						</div>
					</div>
				))}
			</div>
		</Layout>
	);
};

export default AllUsers;
