import React, { useContext, useEffect, useState } from "react";
import { removeFile, serverUrl } from "../../fileManager";
import txt from "../../image/txt.png";
import zip from "../../image/zip.PNG";
import { StoreProvider } from "../ContextProvider";
import Spinner from "../Spinner";

const FileSection = (props) => {
  const {
    isSelect,
    loadFiles,
    previewFile,
    setOpenModal,
    filteredFile,
    setPreviewFile,
    handleSelectPreviewFile,
  } = props;
  const { token } = useContext(StoreProvider);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCopyURL, setIsCopyURL] = useState(false);
  const [isCopyName, setIsCopyName] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState({});
  const [fileUrl, setFileUrl] = useState("");

  const handleDeleteFile = async (filename) => {
    const isConfirm = window.confirm("Are you sure delete this file?");
    if (isConfirm) {
      setIsDeleting(true);
      const res = await removeFile(filename, token);
      setIsDeleting(false);
      setDeleteResponse(res);
      if (!res?.isError) {
        loadFiles();
        setPreviewFile(null);
      }
    }
  };

  useEffect(() => {
    loadFiles();
  }, []);

  useEffect(() => {
    setIsCopyURL(false);
    setIsCopyName(false);
    if (previewFile?.fileLink) {
      setFileUrl(`${serverUrl}${previewFile?.fileLink}`);
    }
  }, [previewFile]);

  return (
    <div className="d-flex">
      <div
        className="d-flex flex-wrap"
        style={{ width: previewFile?.filename ? "90%" : "100%" }}
      >
        {filteredFile?.length > 0 &&
          filteredFile?.map((file) => (
            <div
              key={file?.filename}
              className="pointer card me-2 mb-2"
              style={{ width: "16rem" }}
              onClick={() => setPreviewFile(file)}
            >
              {file?.mime_type?.includes("image") && (
                <img
                  src={`${serverUrl}${file?.fileLink}`}
                  style={{
                    height: "200px",
                    objectFit: "fill",
                    cursor: "pointer",
                  }}
                  className="card-img-top"
                  alt=""
                />
              )}

              {file?.mime_type?.includes("application") && (
                <img
                  src={zip}
                  className="card-img-top"
                  style={{
                    height: "200px",
                    objectFit: "fill",
                    cursor: "pointer",
                  }}
                  alt=""
                />
              )}

              {file?.mime_type?.includes("text") && (
                <img
                  src={txt}
                  className="card-img-top"
                  style={{
                    height: "200px",
                    objectFit: "fill",
                    cursor: "pointer",
                  }}
                  alt=""
                />
              )}

              <div className="card-body">
                <p className="card-text">
                  {file?.filename?.length > 10
                    ? `${file?.filename.slice(0, 10)}...${file?.filename?.slice(
                        file?.filename?.length - 7,
                        file?.filename?.length
                      )}`
                    : file?.filename}
                </p>
              </div>
            </div>
          ))}
      </div>
      {previewFile?.filename && (
        <div>
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h1>File info</h1>
            <div>
              <button
                className=" btn btn-warning p-2"
                onClick={() => setPreviewFile(null)}
              >
                Close
              </button>
            </div>
          </div>
          <div>
            {deleteResponse?.message && (
              <p
                className={
                  deleteResponse?.isError ? "text-danger" : "text-success"
                }
              >
                {deleteResponse?.message}
              </p>
            )}
          </div>
          <div className="w-100">
            {previewFile?.mime_type?.includes("image") && (
              <img
                src={`${serverUrl}${previewFile?.fileLink}`}
                style={{
                  width: "250px",
                  height: "200px",
                  objectFit: "fill",
                  cursor: "pointer",
                }}
                className="card-img-top"
                alt=""
              />
            )}

            {previewFile?.mime_type?.includes("application") && (
              <img
                src={zip}
                className="card-img-top"
                style={{
                  height: "200px",
                  objectFit: "fill",
                  cursor: "pointer",
                }}
                alt=""
              />
            )}

            {previewFile?.mime_type?.includes("text") && (
              <img
                src={txt}
                className="card-img-top"
                style={{
                  height: "200px",
                  objectFit: "fill",
                  cursor: "pointer",
                }}
                alt=""
              />
            )}
            <div>
              <p className="mt-2 mb-0">File name: {previewFile?.filename} </p>
              <p className="mt-1">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    navigator.clipboard.writeText(previewFile?.filename);
                    setIsCopyName(true);
                  }}
                  id="basic-addon2"
                >
                  {isCopyName ? "Copied" : "Copy Name"}
                </button>
              </p>
              <p className="mb-0">Visibility: {previewFile?.type}</p>
              <p className="mb-0">File Size: {previewFile?.size}</p>
              <p className="mb-0">Upload Time: {previewFile?.uploaded_at}</p>

              <div className="input-group mb-5 mt-3">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={fileUrl}
                  placeholder="file Url"
                />
                <span
                  className="input-group-text btn-primary"
                  role="button"
                  onClick={() => {
                    navigator.clipboard.writeText(fileUrl);
                    setIsCopyURL(true);
                  }}
                  id="basic-addon2"
                >
                  {isCopyURL ? "Copied" : "Copy URL"}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                {isSelect && (
                  <button
                    onClick={() => {
                      handleSelectPreviewFile(previewFile);
                      setOpenModal(false);
                      setPreviewFile(null);
                    }}
                    className="btn btn-primary"
                  >
                    Use File
                  </button>
                )}
                <button
                  disabled={isDeleting ? true : false}
                  onClick={() => handleDeleteFile(previewFile?.filename)}
                  className="btn btn-danger"
                >
                  Delete{isDeleting && <Spinner />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileSection;
