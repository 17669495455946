import ImageUploader from "quill-image-uploader";
import React, { useContext, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import {
  editProduct,
  getAllProducts,
  getAllTitleDesc,
  imageHandler,
} from "../api";
import styles from "../styles/AddProduct.module.scss";
import { StoreProvider } from "./ContextProvider";
import FileManagerModal from "./FileManager/FileManagerModal";
import Spinner from "./Spinner";
import { toast } from "react-toastify";

Quill.register("modules/imageUploader", ImageUploader);

const modules = {
  toolbar: [
    [{ font: ["inter"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    ["clean"],
  ],
  imageUploader: {
    upload: imageHandler,
  },
};

const EditProduct = ({ product }) => {
  const { setAllProduct, token } = useContext(StoreProvider);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [extraData, setExtraData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFeatured, setIsFeatured] = useState(true);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [short_detail, setShortDetail] = useState("");
  const [zipFiles, setZipFiles] = useState({});
  const [licenseFiles, setLicenseFiles] = useState({});
  const [fileStatus, setFileStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAdd = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("product_id", extraData?.product_id);
    formData.append("name", extraData?.name);
    formData.append(
      "slug",
      extraData?.slug?.toString().trim().replace(/\s+/g, "-").toLowerCase()
    );
    formData.append("built_with", extraData?.built_with);
    formData.append("current_version", extraData?.current_version);
    formData.append("preview_link", extraData?.preview_link);
    formData.append("default_variation", extraData?.default_variation);
    formData.append("short_detail", short_detail);
    formData.append("is_featured", isFeatured);
    formData.append("sales", product?.sales);
    formData.append("earnings", product?.earnings);
    formData.append("tags", JSON.stringify(selectedTags));
    formData.append("detail", value || " ");
    formData.append("image", featuredImage);
    formData.append(
      "price",
      JSON.stringify({
        free: extraData?.freeFile?.length > 0 ? 0 : 120,
        starter: zipFiles?.starterFile ? extraData?.starter : 0,
        business: zipFiles?.businessFile ? extraData?.business : 0,
        extend: zipFiles?.extendFile ? extraData?.extend : 0,
      })
    );
    formData.append(
      "download_file",
      JSON.stringify({
        freeFile: zipFiles?.freeFile || "",
        starterFile: zipFiles?.starterFile || "",
        businessFile: zipFiles?.businessFile || "",
        extendFile: zipFiles?.extendFile || "",
      })
    );
    formData.append(
      "download_file_license",
      JSON.stringify({
        freeFile: licenseFiles?.freeFile || "",
        starterFile: licenseFiles?.starterFile || "",
        businessFile: licenseFiles?.businessFile || "",
        extendFile: licenseFiles?.extendFile || "",
      })
    );
    const response = await editProduct(formData, token);
    setIsLoading(false);
    if (!response?.isError) {
      const newProducts = await getAllProducts(token);
      setAllProduct(newProducts?.products);
      toast.success(response?.message || "Product updated successfully");
      // navigate(`/detail/${response?.product?.product_id}`);
      return;
    }
    toast.error(response?.message || "An error occurred, please try again");
  };

  const handleChange = (e) => {
    const data = {
      ...extraData,
      [e.target.name]: e.target.value,
    };
    setExtraData(data);
  };

  const handleTags = (tagString) => {
    if (tagString) {
      const tag = JSON.parse(tagString);
      const existingTag = selectedTags.find((item) => String(item?.id) === String(tag?.id));
      if (!existingTag) {
        setSelectedTags([...selectedTags, tag]);
      }
    };
  }

  const removeTag = (tagId) => {
    const existingTag = selectedTags.filter(
      (item) => String(item?.id) !== String(tagId)
    );
    setSelectedTags(existingTag);
  };

  const setData = () => {
    setTimeout(() => {
      setSelectedTags(product?.tags);
      setIsFeatured(product?.is_featured);
      setValue(product?.detail);
      setShortDetail(product?.short_detail);
      setLicenseFiles(product?.download_file_license);
      setZipFiles(product?.download_file);
      setFeaturedImage(product?.image);
      setExtraData({
        product_id: product?.product_id,
        name: product?.name,
        slug: product?.slug,
        built_with: product?.built_with,
        preview_link: product?.preview_link,
        current_version: product?.current_version,
        default_variation: product?.default_variation,
        ...product?.price,
      });
    }, 2000);
  };

  const handleFileManagerModal = (type, status) => {
    setIsModalOpen(true);
    setFileStatus({ type, status });
  };

  const handleSelectPreviewFile = (selectedFile) => {
    if (fileStatus?.type === "template") {
      const newFile = {
        ...zipFiles,
        [fileStatus.status]: selectedFile?.filename,
      };
      setZipFiles(newFile);
    } else if (fileStatus?.type === "license") {
      const newLicenseFile = {
        ...licenseFiles,
        [fileStatus.status]: selectedFile?.filename,
      };
      setLicenseFiles(newLicenseFile);
    } else if (fileStatus?.type === "featuredImage") {
      setFeaturedImage(selectedFile?.filename);
    }
  };

  const loadTags = async () => {
    const response = await getAllTitleDesc(token);
    if (!response?.isError && response?.data) {
      setAllTags(response?.data);
    }
  };

  useEffect(() => {
    setData();
    loadTags();
  }, [product]);

  const handleEditor = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setShortDetail(
      editor
        ?.getText()
        ?.slice(0, 140)
        ?.replace(/\s{2,}/g, " ")
        ?.trim()
    );
  };

  return (
    <>
      <div className={styles.AddProduct}>
        <div className={styles.wrapper}>
          <h3>Edit Product</h3>
          <div className={styles.formContainer}>
            <form
              onSubmit={handleAdd}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <div className={`${styles.basic}`}>
                <div className="mb-3 me-5 w-50">
                  <label htmlFor="name" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    defaultValue={product?.name}
                    onInput={handleChange}
                  />
                </div>
                <div className="mb-3 w-50">
                  <label htmlFor="slug" className="form-label">
                    Slug <span className="text-danger">*</span>{" "}
                    <small>slug must be unique for every item</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    name="slug"
                    placeholder="slug"
                    defaultValue={product?.slug}
                    onInput={handleChange}
                  />
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 me-5 w-50">
                  <label htmlFor="built" className="form-label">
                    Built with
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="built"
                    placeholder="example: Tailwind"
                    defaultValue={product?.built_with}
                    name={"built_with"}
                    onInput={handleChange}
                  />
                </div>
                <div className="mb-3 w-50">
                  <label htmlFor="version" className="form-label">
                    Version
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="version"
                    placeholder="example: 0.1.1"
                    defaultValue={product?.current_version}
                    name={"current_version"}
                    onInput={handleChange}
                  />
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 me-5 w-50">
                  <label htmlFor="preview" className="form-label">
                    Preview Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="preview"
                    placeholder="example: https://www.exemple.com"
                    defaultValue={product?.preview_link}
                    name={"preview_link"}
                    onInput={handleChange}
                  />
                </div>
                <div className="mb-3 w-50 d-flex justify-content-between align-items-center">
                  <p>Featured Image: {featuredImage}</p>
                  <button
                    type="button"
                    onClick={() =>
                      handleFileManagerModal("featuredImage", "featuredImage")
                    }
                    className="btn btn-primary ms-auto"
                  >
                    Select
                  </button>
                </div>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-3 w-50">
                  <label htmlFor="isFeatured" className="form-label">
                    Is Featured
                  </label>
                  <select
                    className="form-control"
                    id="isFeatured"
                    value={isFeatured}
                    defaultValue={isFeatured}
                    onChange={(e) => setIsFeatured(JSON.parse(e.target.value))}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
                <div className="mb-3 ms-5 w-50">
                  <img
                    width={500}
                    // src={
                    //   featuredImage?.size
                    //     ? URL.createObjectURL(featuredImage)
                    //     : `${process.env.REACT_APP_SERVER_CONNECT}/public/file/${product?.image}`
                    // }
                    src={
                      featuredImage &&
                      `${process.env.REACT_APP_SERVER_CONNECT}/public/file/${featuredImage}`
                    }
                    alt=""
                  />
                </div>
              </div>

              <div className={`${styles.basic}`}>
                <div className="mb-3 w-50">
                  <label htmlFor="taf" className="form-label">
                    Tags
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      if (e?.currentTarget?.value) {
                        handleTags(e?.currentTarget?.value);
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {allTags?.map((tag) => (
                      <option key={tag?.id} value={JSON.stringify(tag)}>
                        {tag?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 ms-4 w-50">
                  <h5>Tags</h5>
                  {selectedTags?.map((tag) => (
                    <span
                      key={tag?.id}
                      className="block rounded bg-primary text-light p-1 pe-0 me-2 mb-2"
                    >
                      {tag?.name}{" "}
                      <span
                        onClick={() => removeTag(tag?.id)}
                        title="delete"
                        style={{ cursor: "pointer" }}
                        className="rounded-circle ms-2 bg-danger p-1"
                      >
                        x
                      </span>
                    </span>
                  ))}
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-around">
                {/* product zip filename */}
                <div className="w-50 me-5">
                  <h5 className="mb-4 mt-5">Template zip file</h5>
                  <div className="mb-4 d-flex">
                    <p>Free version: {zipFiles?.freeFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "freeFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Starter version: {zipFiles?.starterFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "starterFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Business version: {zipFiles?.businessFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "businessFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Extended version: {zipFiles?.extendFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("template", "extendFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                </div>
                {/* product txt filename */}
                <div className="w-50 ms-5">
                  <h5 className="mb-4 mt-5">Template license file</h5>
                  <div className="mb-4 d-flex">
                    <p>Free version: {licenseFiles?.freeFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "freeFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Starter version: {licenseFiles?.starterFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "starterFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Business version: {licenseFiles?.businessFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "businessFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                  <div className="mb-4 d-flex">
                    <p>Extended version: {licenseFiles?.extendFile}</p>
                    <button
                      type="button"
                      onClick={() =>
                        handleFileManagerModal("license", "extendFile")
                      }
                      className="btn btn-primary ms-auto"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>

              {/* Default Variation */}
              <div className="d-flex align-items-center">
                <h5 className="mb-4 mt-4 me-4">Default Variation: </h5>
                <select
                  className="p-2"
                  value={extraData?.default_variation}
                  defaultValue={extraData?.default_variation}
                  onChange={(e) =>
                    setExtraData({
                      ...extraData,
                      default_variation: e.target.value,
                    })
                  }
                >
                  <option
                    value="free"
                    selected={
                      extraData?.default_variation === "free" ? true : false
                    }
                  >
                    Free
                  </option>
                  <option
                    value="starter"
                    selected={
                      extraData?.default_variation === "starter" ? true : false
                    }
                  >
                    Starter
                  </option>
                  <option
                    value="business"
                    selected={
                      extraData?.default_variation === "business" ? true : false
                    }
                  >
                    Business
                  </option>
                  <option
                    value="extended"
                    selected={
                      extraData?.default_variation === "extended" ? true : false
                    }
                  >
                    Extended
                  </option>
                </select>
              </div>

              {/* price */}
              <h5 className="mb-4 mt-4">Price</h5>
              <div className={`${styles.basic}`}>
                <div className="mb-3 w-25">
                  <label htmlFor="price2" className="form-label">
                    Starter version
                  </label>
                  <input
                    type="number"
                    name="starter"
                    className="form-control"
                    defaultValue={extraData?.starter}
                    id="price2"
                    placeholder="example: 50"
                    onInput={handleChange}
                  />
                </div>
                <div className="mb-3 me-5 w-25">
                  <label htmlFor="price3" className="form-label">
                    Business version
                  </label>
                  <input
                    type="number"
                    name="business"
                    className="form-control"
                    id="price3"
                    placeholder="example: 100"
                    defaultValue={extraData?.business}
                    onInput={handleChange}
                  />
                </div>
                <div className="mb-3 w-25">
                  <label htmlFor="price4" className="form-label">
                    Extended version
                  </label>
                  <input
                    type="number"
                    name="extend"
                    className="form-control"
                    id="price4"
                    placeholder="example: 400"
                    defaultValue={extraData.extend}
                    onInput={handleChange}
                  />
                </div>
              </div>

              {/* editor */}
              <div className="mt-5 mb-5 text-center">
                <h1>
                  {"<"} Here is Editor {"/>"}
                </h1>
              </div>
              <div className={`${styles.basic}`}>
                <div className="mb-4 w-75">
                  <label
                    htmlFor="shortDetail"
                    className="fw-bold fs-5 form-label pe-3"
                  >
                    Short Detail{" "}
                    <span>
                      {short_detail?.length ? short_detail?.length : "0"} /140
                    </span>
                  </label>
                  <textarea
                    style={{ resize: "none" }}
                    type="text"
                    className="form-control"
                    id="shortDetail"
                    name="short_detail"
                    value={short_detail}
                    placeholder="Short Detail"
                    maxLength={140}
                    rows="2"
                  // onInput={handleChange}
                  />
                </div>
              </div>
              <h4>Full Detail</h4>
              <ReactQuill
                modules={modules}
                theme="snow"
                value={value}
                onChange={handleEditor}
              />
              <div className="text-center mt-5 mb-5">
                <p className="mb-4 text-danger">
                  Please check again all information provided are correct
                </p>
                <button
                  className="btn btn-primary"
                  disabled={isLoading ? true : false}
                  type="submit"
                >
                  Update Template {isLoading && <Spinner />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FileManagerModal
        isSelect={true}
        openModal={isModalOpen}
        handleSelectPreviewFile={handleSelectPreviewFile}
        setOpenModal={setIsModalOpen}
      />
    </>
  );
};

export default EditProduct;
