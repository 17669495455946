import React from "react";
import Layout from "../components/Layout";
import { indexSearch, clearSearch } from "../api";

import { toast } from "react-toastify";

const OperationsPage = () => {
  const handleSearchIndex = async () => {
    try {
      await indexSearch();
      toast.success("Search indexed successfully");
    } catch (error) {
      toast.error("Unable to index search");
    }
  };

  const handleSearchClean = async () => {
    try {
      await clearSearch();
      toast.success("Search cleared successfully");
    } catch (error) {
      toast.error("Unable to clear search");
    }
  };

  return (
    <Layout>
      <div className="login mt-5">
        <button className="btn btn-info m-3" onClick={handleSearchIndex}>
          Index Serach
        </button>
        <button className="btn btn-warning" onClick={handleSearchClean}>
          Purge Search
        </button>
      </div>
    </Layout>
  );
};

export default OperationsPage;
