import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserTransaction, getUsers } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";

const TransactionPage = () => {
  const { token } = useContext(StoreProvider);
  const [user, setUser] = useState({});
  const [allTransact, setAllTransact] = useState([]);
  const { user_id } = useParams();
  const loadUser = async (id) => {
    const res = await getUsers(id, token);
    if (!res?.isError) {
      const transact = await getUserTransaction(res?.user?.email, token);
      if (!transact?.isError) {
        setAllTransact(transact?.data);
      }
      setUser(res?.user);
    }
  };
  useEffect(() => {
    loadUser(user_id);
  }, [user_id]);
  return (
    <Layout>
      <h2 className="card-title mt-3 mb-4">
        User Transaction
        <Link title="Edit user" to={`/user/edit/${user?.id}`}>
          <button className="btn btn-warning ms-5 ps-4 pe-4">Edit User</button>
        </Link>
        <Link title="Transaction" to={`/user/detail/${user?.id}`}>
          <button className="btn btn-primary ms-5 ps-4 pe-4">
            User Detail
          </button>
        </Link>
        <Link title="All User" to={`/all-user`}>
          <button className="btn btn-info ms-5 ps-4 pe-4">All User</button>
        </Link>
      </h2>
      <div style={{ fontSize: "20px" }}>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "80px" }}>Name</p>
          <p>
            :{" "}
            {user?.first_name && (
              <b className="ms-3">{user?.first_name + " " + user?.last_name}</b>
            )}
          </p>
        </div>
        <div className="mb-1 d-flex w-75">
          <p style={{ width: "80px" }}>Email</p>
          <p>
            : <b className="ms-3">{user?.email}</b>
          </p>
        </div>
      </div>
      <div className="mt-3 table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Payment Name</th>
              <th scope="col">Payment Email</th>
              <th scope="col">Receipt URL</th>
            </tr>
          </thead>
          <tbody>
            {allTransact.map((item) => (
              <tr>
                <th scope="row">{item?.id}</th>
                <td>{item?.payment_name}</td>
                <td>{item?.payment_email}</td>
                <td>{item?.receipt_url.slice(0, 35)}...</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default TransactionPage;
