import { useContext, useEffect, useState } from "react";
import { getAllAccessPrice, updateAllAccessPrice } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";

const AllAccessPrice = () => {
	const { token } = useContext(StoreProvider);
	const [starter, setStarter] = useState(null);
	const [business, setBusiness] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState({});

	const loadGetAllAccessPrice = async () => {
		const res = await getAllAccessPrice();
		if (!res?.isError) {
			setStarter(res.allAccessPrice[0]);
			setBusiness(res.allAccessPrice[1]);
		}
	};

	const handleUpdatePrice = async (info) => {
		setIsLoading(true);
		setIsError({});
		const formData = new FormData();
		formData.append("name", info?.name);
		formData.append("price", info?.price);
		formData.append("previous_price", info?.previous_price);
		const res = await updateAllAccessPrice(formData, token);
		setIsLoading(false);
		setIsError(res);
	};

	useEffect(() => {
		loadGetAllAccessPrice();
	}, []);

	return (
		<Layout>
			<h2 className="card-title mt-3 mb-3">All Access Price</h2>
			<p className={isError?.isError ? "text-danger" : "text-success"}>
				{isError?.message}
			</p>
			<div className="d-flex justify-content-between">
				<div className="w-50 me-5">
					<h4>Startup Price</h4>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleUpdatePrice(starter);
						}}
					>
						<div className="mb-4 form-group">
							<div className="d-flex align-items-center">
								<label htmlFor="price">New Price: </label>
								<input
									onChange={(e) =>
										setStarter({ ...starter, price: Number(e.target.value) })
									}
									defaultValue={starter?.price}
									type="number"
									className="w-75 ms-4 form-control"
									id="price"
									placeholder="New Price"
									required
								/>
							</div>
						</div>
						<div className="mb-4 form-group">
							<div className="d-flex align-items-center">
								<label htmlFor="oldPrice">Old Price: </label>
								<input
									onChange={(e) =>
										setStarter({
											...starter,
											previous_price: Number(e.target.value),
										})
									}
									defaultValue={starter?.previous_price}
									type="number"
									className="w-75 ms-4 form-control"
									id="oldPrice"
									placeholder="Old Price"
									required
								/>
							</div>
						</div>
						<button
							disabled={isLoading ? true : false}
							type="submit"
							className="btn btn-primary"
						>
							Update Startup {isLoading && <Spinner />}
						</button>
					</form>
				</div>
				<div className="w-50 ms-5">
					<h4>Business Price</h4>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleUpdatePrice(business);
						}}
					>
						<div className="mb-4 form-group">
							<div className="d-flex align-items-center">
								<label htmlFor="bprice">New Price: </label>
								<input
									onChange={(e) =>
										setBusiness({
											...business,
											price: Number(e.target.value),
										})
									}
									defaultValue={business?.price}
									type="number"
									className="w-75 ms-4 form-control"
									id="bprice"
									placeholder="New Price"
									required
								/>
							</div>
						</div>
						<div className="mb-4 form-group">
							<div className="d-flex align-items-center">
								<label htmlFor="boldPrice">Old Price: </label>
								<input
									onChange={(e) =>
										setBusiness({
											...business,
											previous_price: Number(e.target.value),
										})
									}
									defaultValue={business?.previous_price}
									type="number"
									className="w-75 ms-4 form-control"
									id="boldPrice"
									placeholder="Old Price"
									required
								/>
							</div>
						</div>
						<button
							disabled={isLoading ? true : false}
							type="submit"
							className="btn btn-primary"
						>
							Update Business {isLoading && <Spinner />}
						</button>
					</form>
				</div>
			</div>
		</Layout>
	);
};

export default AllAccessPrice;
