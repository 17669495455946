import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { updateUser, updateUserPassword } from "../api";
import Spinner from "../components/Spinner";
import { StoreProvider } from "./ContextProvider";

const EditUser = ({ user, setUser }) => {
	const { token } = useContext(StoreProvider);
	const [loading, setLoading] = useState(false);
	const [infoRes, setInfoRes] = useState({});
	const [passwordRes, setPasswordRes] = useState({});
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const handleChange = (e) => {
		user[e.target.name] = e.target.value;
		const newUser = { ...user };
		setUser(newUser);
	};
	const handleUpdateInfo = async (e) => {
		e.preventDefault();
		const confirm = window.confirm("Are you sure to update user's info?");
		if (confirm) {
			setLoading(true);
			setInfoRes({});
			const fd = new FormData();
			fd.append("id", user?.id);
			fd.append("email", user?.email);
			fd.append("name", user?.name);
			fd.append("first_name", user?.first_name);
			fd.append("last_name", user?.last_name);
			fd.append("company_name", user?.company_name);
			fd.append("location", user?.location);
			const res = await updateUser(fd, token);
			setInfoRes(res);
			setLoading(false);
		}
	};

	const handleUpdatePassword = async (e) => {
		e.preventDefault();
		if (password === confirmPassword) {
			setPasswordRes({});
			const confirm = window.confirm("Are you sure to change user's password?");
			if (confirm) {
				setLoading(true);
				const formData = new FormData();
				formData.append("id", user?.id);
				formData.append("password", password);
				const response = await updateUserPassword(formData, token);
				setPasswordRes(response);
				setLoading(false);
			}
		} else {
			setPasswordRes({
				isError: true,
				message: "Password does not matched with confirm password",
			});
		}
	};
	return (
		<div>
			<h2 className="card-title mt-3 mb-4">
				Update User info
				<Link title="user detail" to={`/user/detail/${user?.id}`}>
					<button className="btn btn-warning ms-5 ps-4 pe-4">
						User Detail
					</button>
				</Link>
				<Link title="Transaction" to={`/user/transaction/${user?.id}`}>
					<button className="btn btn-success ms-5 ps-4 pe-4">
						Transaction
					</button>
				</Link>
				<Link title="All User" to={`/all-user`}>
					<button className="btn btn-info ms-5 ps-4 pe-4">All User</button>
				</Link>
			</h2>
			<div style={{ fontSize: "20px" }}>
				<p className={infoRes?.isError ? "text-danger" : "text-success"}>
					{infoRes?.message}
				</p>
				<form onSubmit={handleUpdateInfo}>
					<div className="mb-1 d-flex w-75">
						<p style={{ width: "200px" }}>Email</p>
						<p>
							:{" "}
							<input
								type="email"
								name="email"
								id="email"
								className="user_input"
								placeholder="Email"
								onChange={handleChange}
								defaultValue={user?.email}
								required
							/>
						</p>
					</div>
					<div className="mb-1 d-flex w-75">
						<p style={{ width: "200px" }}>Full Name</p>
						<p>
							:{" "}
							<input
								type="text"
								name="name"
								id="name"
								className="user_input"
								placeholder="Full Name"
								onChange={handleChange}
								defaultValue={user?.name}
								required
							/>
						</p>
					</div>
					<div className="mb-1 d-flex w-75">
						<p style={{ width: "200px" }}>First Name</p>
						<p>
							:{" "}
							<input
								type="text"
								name="first_name"
								id="first_name"
								className="user_input"
								placeholder="First name"
								onChange={handleChange}
								defaultValue={user?.first_name}
							/>
						</p>
					</div>
					<div className="mb-1 d-flex w-75">
						<p style={{ width: "200px" }}>Last Name</p>
						<p>
							:{" "}
							<input
								type="text"
								name="last_name"
								id="last_name"
								className="user_input"
								placeholder="Last name"
								onChange={handleChange}
								defaultValue={user?.last_name}
							/>
						</p>
					</div>
					<div className="mb-1 d-flex w-75">
						<p style={{ width: "200px" }}>Company Name</p>
						<p>
							:{" "}
							<input
								type="text"
								name="company_name"
								id="company_name"
								className="user_input"
								placeholder="Company Name"
								onChange={handleChange}
								defaultValue={user?.company_name}
							/>
						</p>
					</div>
					<div className="mb-1 d-flex w-75">
						<p style={{ width: "200px" }}>Location</p>
						<p>
							:{" "}
							<input
								type="text"
								name="location"
								id="location"
								className="user_input"
								placeholder="Location"
								onChange={handleChange}
								defaultValue={user?.location}
							/>
						</p>
					</div>
					<button type="submit" className="btn btn-primary mt-3 mb-3">
						update info{loading && <Spinner />}
					</button>
				</form>
				<div className="mt-3 mb-5">
					<h3 className="mb-3">Update user password</h3>
					<form onSubmit={handleUpdatePassword}>
						<p
							className={passwordRes?.isError ? "text-danger" : "text-success"}
						>
							{passwordRes?.message}
						</p>
						<div className="d-flex">
							<div className="me-5">
								<label htmlFor="password">New Password</label>
								<input
									type="text"
									onChange={(e) => setPassword(e.target.value.trim())}
									className="form-control"
									name="password"
									id="password"
									required
								/>
							</div>
							<div>
								<label htmlFor="confirmPassword">Confirm Password</label>
								<input
									type="text"
									onChange={(e) => setConfirmPassword(e.target.value.trim())}
									className="form-control"
									name="confirmPassword"
									id="confirmPassword"
									required
								/>
							</div>
						</div>
						<button type="submit" className="btn btn-primary mt-4">
							update password {loading && <Spinner />}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditUser;
