import React, { useContext, useState } from "react";
import { uploadFile } from "../../fileManager";
import svg from "../../image/ss.PNG";
import { StoreProvider } from "../ContextProvider";
import Spinner from "../Spinner";

const UploadImageSection = ({ loadFiles }) => {
  const { token } = useContext(StoreProvider);
  const [filetype, setFileType] = useState("public");
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [res, setRes] = useState({});

  const handleUpload = async () => {
    if (selectedFile?.size > 0) {
      setIsUploading(true);
      const fd = new FormData();
      fd.append("type", filetype);
      fd.append("file", selectedFile);
      const res = await uploadFile(fd, token);
      setRes(res);
      loadFiles();
      setIsUploading(false);
    }
  };

  return (
    <div>
      <h1>Upload File</h1>
      {res?.message && (
        <p className={res?.isError ? "text-danger" : "text-success"}>
          {res?.message}
        </p>
      )}
      <p className="mt-3">
        Select File Type:{" "}
        <select onChange={(e) => setFileType(e.target.value)} className="p-2">
          <option value="public">Public</option>
          <option value="private">Private</option>
        </select>
      </p>
      {selectedFile?.name && <p>File: {selectedFile?.name}</p>}
      <div
        style={{ height: "400px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="card border-0">
          <div className="dropzone">
            <div className="">
              <img
                src={svg}
                className="upload-icon d-block ms-auto me-auto"
                alt=""
              />
              <label htmlFor="">Select File</label>
            </div>
            <input
              onChange={(e) => {
                if (e.target.files[0]?.size > 0) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
              type="file"
              className="upload-input"
              multiple={false}
            />
          </div>
          <button
            disabled={isUploading ? true : false}
            onClick={handleUpload}
            className="btn btn-primary mt-3"
          >
            Upload File {isUploading && <Spinner />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImageSection;
