import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProduct } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import EditProduct from "../components/EditProduct";
import Layout from "../components/Layout";

const EditProductPage = () => {
	const { token } = useContext(StoreProvider);
	const { product_id } = useParams();
	const [product, setProduct] = useState({});
	const loadProduct = async (productId) => {
		const response = await getProduct(productId, token);
		if (!response?.isError) {
			setProduct(response?.productObj);
		}
	};

	useEffect(() => {
		loadProduct(product_id);
	}, [product_id]);
	return (
		<Layout>
			<EditProduct product={product} />
		</Layout>
	);
};

export default EditProductPage;
