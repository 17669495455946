import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addTitleDesc } from "../api";
import { StoreProvider } from "../components/ContextProvider";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    ["blockquote"],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

const AddTagTitlePage = () => {
  const { token } = useContext(StoreProvider);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const handleChange = (e) => {
    const detail = {
      ...data,
      [e.target.name]: e.target.value,
    };
    setData(detail);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await addTitleDesc(data, token);
      setLoading(false);
      console.log(response);
      if (!response?.isError) {
        toast.success(response?.message || "Title added successfully");
        navigate("/tag-title");
        return;
      }
      toast.error(response?.message || "An error occurred, please try again");
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "An error occurred, please try again");
    }
    return;
  };

  const handleEditor = (content, delta, source, editor) => {
    setData({ ...data, description: editor.getHTML() });
  };


  return (
    <Layout>
      <div>
        <h2 className="card-title mt-3 mb-3">Add Title and Description</h2>
        <div>
          <form onSubmit={e => {
            e.preventDefault();
            handleSave();
          }}>
            <div className="d-flex align-items-center mb-4">
              <h5>Name:</h5>
              <input
                className="w-75 p-2 ms-4 w-25"
                type="text"
                name="name"
                placeholder="Name"
                onChange={handleChange}
                required
              />
            </div>
            <div className="d-flex align-items-center">
              <h5>Slug:</h5>
              <input
                className="w-75  p-2  ms-5 w-25"
                type="text"
                name="slug"
                placeholder="Slug"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-4 d-flex align-items-center">
              <h5>Title:</h5>
              <input
                className="w-75  p-2  ms-5 w-25"
                type="text"
                name="title"
                placeholder="Title"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4 d-flex">
              <h5>Description: </h5>
              <ReactQuill
                modules={modules}
                theme="snow"
                className="w-75 ms-1"
                value={data?.description}
                onChange={handleEditor}
              />
            </div>

            <div className="mt-4 d-flex align-items-center">
              <h5>OG Title:</h5>
              <input
                className="w-75 p-2 ms-4 w-25"
                type="text"
                name="og_title"
                placeholder="Open Graph Title"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4 d-flex align-items-center">
              <h5>OG Desc:</h5>
              <textarea
                className="w-75 p-2 ms-4 w-25"
                name="og_description"
                rows={5}
                placeholder="Open Graph Description"
                onChange={handleChange}
              />
            </div>


            <div className="mt-4 d-flex align-items-center">
              <h5>Image url:</h5>
              <input
                className="w-75 p-2 ms-3 w-25"
                type="text"
                name="image_link"
                placeholder="Image url"
                onChange={handleChange}
              />
            </div>
            <div className="text-center mt-5 mb-5">
              <button
                disabled={loading ? true : false}
                type="submit"
                className="btn btn-primary ps-3 pe-3"
              >
                Save {loading && <Spinner />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddTagTitlePage;
