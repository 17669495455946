import React from "react";
import AddProduct from "../components/AddProduct";
import Layout from "../components/Layout";

const AddProductPage = () => {
	return (
		<Layout>
			<AddProduct />
		</Layout>
	);
};

export default AddProductPage;
