import React, { createContext, useEffect, useState } from "react";
import { checkAdmin, generateCookie, getAllProducts, getCookie } from "../api";

export const StoreProvider = createContext();

const ContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState({});
  const [allProducts, setAllProduct] = useState([]);
  const [token, setToken] = useState(getCookie());
  const products = async () => {
    const response = await getAllProducts(token);
    if (!response?.isError) {
      setAllProduct(response?.products);
    }
  };

  const isLoggedIn = async () => {
    const authenticate = await checkAdmin(token);
    if (!authenticate?.isError && authenticate?.isVerified) {
      setAdmin(authenticate);
    } else {
      generateCookie("", 0);
    }
  };

  useEffect(() => {
    setToken(getCookie());
    isLoggedIn();
    products();
  }, [token]);
  return (
    <StoreProvider.Provider
      value={{ admin, setAdmin, allProducts, setAllProduct, token, setToken }}
    >
      {children}
    </StoreProvider.Provider>
  );
};

export default ContextProvider;
