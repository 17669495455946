export const serverUrl = process.env.REACT_APP_SERVER_CONNECT;
const clientURL = "https://admin.tailtemplates.com";

export async function sendDataToServer(product, urlSuffix, method, token) {
  return await fetch(`${serverUrl}/filemanager${urlSuffix}`, {
    method: method,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      enctype: "multipart/form-data",
      accessToken: `Bearer ${token}`,
      originUrl: clientURL,
    },
    body: product,
  }).then((res) => res.json());
}

async function getMethod(urlSuffix, token) {
  try {
    return await fetch(`${serverUrl}/filemanager${urlSuffix}`, {
      method: "GET",
      headers: {
        accessToken: `Bearer ${token}`,
        originUrl: clientURL,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log("error", error);
  }
}

async function removeMethod(urlSuffix, token) {
  try {
    return await fetch(`${serverUrl}/filemanager${urlSuffix}`, {
      method: "DELETE",
      headers: {
        accessToken: `Bearer ${token}`,
        originUrl: clientURL,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log("error", error);
  }
}

export const uploadFile = async (data, token) => {
  return await sendDataToServer(data, "/upload-file", "POST", token);
};

export const getAllFiles = async (token) => {
  return await getMethod("/all-file", token);
};

export const removeFile = async (filename, token) => {
  return await removeMethod(`/remove/${filename}`, token);
};
